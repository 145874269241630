.container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    flex-direction: column;
}

.greetings {
    font-weight: bold;
}

.centeredDiv {
    text-align: center;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.description {
    margin-bottom: 20px;
    width: 50%;
}

.card, .gradientCard {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 20px;
    margin-bottom: 10px;
    border-radius: 5px;
    background: white;
    cursor: pointer;
    gap: 15vw;
}

.cardText {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.gradientCard {
    background: linear-gradient(95.02deg, #7372FF 5.16%, #9772FF 85.19%);
    color: white;
}

.arrow {
    margin-left: 10px;
}

.ctaButton {
    background: white;
    color: #7372FF;
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
}
