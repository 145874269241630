.wrapper {
  margin: 0;
  display: flex;
  justify-content: space-between;
  height: 100vh;
}

.sidebarWrapper {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 17vw;
  background-color: #f6f7fa;
}

.sectionWrapper {
  width: 83vw;
  background-color: #f6f7fa;
  height: 100%;
}


.welcomeScreen{
  height: 100%;
  width: 100%;
  display: flex;
}

.welcomeScreenLeft {
  height: 100%;
  width: 50%;
  background-color: #7372FF;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  div{
    margin-top: 50px;
    margin-left: 50px;
    width: 100%;
    h3 {
      color: #fff;
      text-align: left
    }
  }
}

.welcomeScreenRight{
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  h3 {
    margin-top: 50px;
    margin-bottom: 26px
  }
  p {
    color: #82898F;
    margin: 0 32px;
    text-align: center;
  }
}