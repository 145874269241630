.wrapper {
  margin: 0;
  padding: 28px 28px 28px 36px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.layout {
  width: 100%;
  padding-top: 80px;
}

.bodyWrapper {
  display: flex;
  flex-direction: column;
}

.body {
  display: flex;
  // justify-content: center;
  // align-items: center;

  .spinWrapper {
    height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.invoiceCard {
  background: #e5e7eb;
  > div {
    display: flex;
    justify-content: space-between;
    padding: 0;
    margin: 0;
    height: 83vh;
    &:before {
      content: none !important;
    }
    &:after {
      content: none !important;
    }
  }
}

.formContainer {
  padding: 2rem;
  font-size: 0.55rem;
  background: white;
}

.cardLeft {
  width: 65%;
  padding: 24px;
}

.cardRight {
  width: 35%;
  padding: 32px 24px;
  background: #fff;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.invoiceStatusSelect {
  display: flex;
  flex-direction: column;
  text-align: center;
  > span {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #3b3f43;
    margin-bottom: 16px;
  }
}

.invoiceCardCTA {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
  > span {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 150%;
    color: #7372ff;
    margin-left: 8px;
  }
}

.invoiceOptions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.deleteBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  > span {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 150%;
    color: #ee6969;
    margin-left: 6px;
  }
}

.zoomBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: #3b3f43 !important;
  > span {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #ffffff;
    margin-left: 6px;
  }
}

.invoiceContainer {
  position: relative;
  overflow: hidden;
  width: 80%;
  height: 94%;
  padding: 0;
  margin: 0 auto;
}

.invoice {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.confirmModal {
  text-align: center;
  .confirmModalInfo {
    background: #fffcf1;
    padding: 16px 27px;
    margin-bottom: 17px;
    > span {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 150%;
      color: #c9a11a;
    }
  }
  .confirmModalCTA {
    display: flex;
    justify-content: space-between;
  }
}

.ant-form-vertical .ant-form-item-label {
  padding: 10px 0 2px;
  line-height: 1.5715;
  white-space: initial;
  text-align: left;
  font-weight: 500;
}

.ant-row .ant-col {
  padding: 0 !important;
  margin: 0 !important;
}

label {
  font-size: 0.75rem !important;
  margin: 10px 0 0 !important;
}

.ant-upload-drag-container {
  background: #E5E7EB !important;
}

.formTable tr {
  padding: 0 !important;
  margin: 10px 0 !important;
}
.formTable td {
  padding: 0 !important;
}
.formTable table-tbody {
  padding: 0 !important;
}
.formTable input {
  border-radius: 0px !important;
}

.Uploader {
  background: #E5E7EB !important;
}

.centeredModalContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center; /* For centering the text */
  padding: 0;
  margin: 0;
}

.centeredModalFooter {
  display: flex;
  justify-content: center;
  gap: 10px; /* Spacing between the buttons */
  margin-top: -5vh;
}

.previewContainer {
  position: absolute;
  display: flex;
  z-index: 1;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  flex-direction: column;
}
