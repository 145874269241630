.usersCard {
  width: 100%;
  height: 165px;
  > div {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
  }
  > div > div:first-child {
    border-right: 1px solid #e5e7eb;
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    > div:first-child {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #3b3f43;
      margin-bottom: 24px;
    }
    > div:last-child {
      width: 67.5px;
      height: 67.5px;
      background: #f3f3ff;
      border-radius: 72px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-style: normal;
      font-weight: 700;
      font-size: 22px;
      line-height: 27px;
      color: #3b3f43;
    }
  }
  > div > div:last-child {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    > div:first-child {
      border-bottom: 1px solid #e5e7eb;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 50%;
      cursor: pointer;
      padding: 0 34px;
    }
    > div:last-child {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 50%;
      padding: 0 34px;
      cursor: pointer;
      > div:last-child {
        width: 26px;
        height: 26px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #7372ff;
        border-radius: 50%;
      }
    }
    > div > div {
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 150%;
      color: #7372ff;
    }
  }
}

.deactivated {
  color: rgb(209, 213, 221) !important;
}

.optionCardComingSoonLabel {
  color: #d3b25a;
  text-transform: uppercase;
  font-style: normal;
  font-weight: normal;
  font-size: 9px;
  line-height: 150%;
}
