@import '../../../theme/constants.scss';

.wrapper {
  margin: 0;
  padding-top: 80px;
  min-height: 100vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.steps {
  margin: 46px auto;
}

.headerWrapper {
  text-align: left;
  margin: 30px 0;
  h1 {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    color: #3b3f43;
  }
  p {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 28px;
    color: #9ba3ab;
    width: 313px;
  }
}

.formWrapper {
  margin: 0 auto 36px auto;
  width: 52%;
}

.links {
  margin: 42px auto;
  text-align: center;
  > span {
    color: #9ba3ab;
  }
}

.otpWrapper {
  input {
    margin-right: 8px;
  }
}

.options {
  display: flex;
  justify-content: space-between;
  margin: 16px auto 8px auto;
  p {
    color: #3f63e2;
    &:hover {
      cursor: pointer;
    }
  }
}

.otpSignIn {
  width: 100%;
  padding: 16px 20px 0 16px;
  background-color: #fcfdff;
  border: 1px solid #e5e7eb;
  border-radius: 6px;
  div {
    font-size: 14px;
    font-weight: 600;
    color: #3b3f43;
  }
  p {
    height: 66px;
    color: #9ba3ab;
    font-weight: 400;
    font-size: 14px;
    padding: 0 24px 16px 28px;
  }
}

.stepsWrapper {
  background: #fff;
  padding-top: 26px;
  padding-bottom: 20px;
  margin: 0;
  position: fixed;
  top: 0;
  right: 0;
  left: 42%;
  z-index: 99;
  // width: 100%;
}

.stepper {
  width: 52%;
  margin: 0 auto;
  // position: relative;
}

.step {
  // width: calc(100% / 8);
  margin-left: -42px;
}

.stepperBackButton {
  margin-top: 20px;
  margin-left: 24%;
}


@media (max-width: $smallMobile) {
  .formWrapper {
    width: 85%
  }
}

@media (max-width: $mobile) {
  .formWrapper {
    width: 75%
  }
}