.notificationCard {
  cursor: pointer;
  flex-basis: 100%;
  flex: 1 20%;
  width: 135px;
  height: 252px;
  margin: 0 12px 0 0;
  transition-duration: 1s, 1s;

  > div {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 24px 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    &:before {
      content: none !important;
    }
    &:after {
      content: none !important;
    }
  }
  h3 {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    color: #3b3f43;
    width: 40%;
  }
}
.notificationCardDisabled {
  color: #d1d5dd;
  flex-basis: 100%;
  flex: 1 20%;
  width: 148px;
  height: 252px;
  margin: 0 12px 0 0;
  transition-duration: 1s, 1s;

  > div {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 24px 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    &:before {
      content: none !important;
    }
    &:after {
      content: none !important;
    }
  }
  h3 {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    color: #3b3f43;
    width: 40%;
  }
}

.notificationDetailsDisabled {
  color: #d1d5dd;
  > div:first-child {
    color: #d3b25a;
    text-transform: uppercase;
    font-style: normal;
    font-weight: normal;
    font-size: 9px;
    line-height: 150%;
  }
  > div:not(:first-child):not(:last-child) {
    margin: 6px 0 20px 0;
    display: flex;
    align-items: flex-start;
    > span:first-child {
      font-style: normal;
      font-weight: 400;
      font-size: 8px;
      line-height: 8px;
      color: #d1d5dd;
      margin-right: 4px;
    }
    > span:last-child {
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 12px;
      color: #d1d5dd;
    }
  }
}

// .notificationCard:hover {
//   background-color: #faeaea;
//   transition-duration: 1s, 1s;
// }
.arrowBtnWithBg {
  color: #fff;
  background-color: #7372ff;
  border-radius: 50%;
  cursor: pointer;
  margin-top: 10px;
}
.arrowBtnWithBgDisabled {
  color: #fff;
  background-color: #d1d5dd;
  border-radius: 50%;
}

.notificationDetails {
  > div:first-child {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #3b3f43;
  }
  > :global(.amount) {
    margin: 6px 0 20px 0;
    display: flex;
    align-items: flex-start;
    > span:first-child {
      font-style: normal;
      font-weight: 400;
      font-size: 8px;
      line-height: 8px;
      color: #82898f;
      margin-right: 4px;
    }
    > span:last-child {
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 12px;
      color: #82898f;
    }
  }
}
.optionCardComingSoonLabel {
    color: #d3b25a;
    text-transform: uppercase;
    font-style: normal;
    font-weight: normal;
    font-size: 9px;
    line-height: 150%;
  }
  .spinner {
    display: block;
    margin-bottom: 20px;
    margin-left: -5px;
  }