.wrapper {
  margin: 0;
  background-color: #7372ff;
  --header-height: 16vh;
  --header-max-height: 157px;
  --form-height: calc(100vh - var(--header-max-height) + 10px);
  --cardForm-height: calc(var(--form-height) - 200px);
  --detailsForm-height: calc(var(--form-height - 150px));
}

.headerWrapper {
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-height: var(--header-max-height);
  height: var(--header-height);
}

.formWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: var(--form-height);
}

.innerFormWrapper {
  background-color: #7372ff;
  height: var(--cardForm-height);
}

.ccFormItemWrapper {
  padding: 0 24px;
}

.detailsFormWrapper {
  background-color: #fff;
  height: var(--form-height);
  padding: 24px;
}

.amountRow {
  display: flex;
  align-items: flex-start;
  margin-bottom: 8px;
}

.dollarSymbol {
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  color: #d1d5dd;
  margin-right: 4px;
}

.amount {
  font-weight: 700;
  font-size: 32px;
  line-height: 39px;
  color: #ffffff;
}

.description {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  max-width: 340px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.label {
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #ffffff;
}

.darkLabel {
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #82898f;
}

.datePicker {
  height: 51px;
  width: 100%;
}

.submitButton {
  width: 100%;
  height: 48px;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
  margin-bottom: 14px;
}

.input {
  border-top: none;
  border-left: none;
  border-right: none;
  border-radius: 0;
  border-bottom: 1px solid #d1d5dd;
  height: 44px;
  box-shadow: none;
  &:hover {
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.11);
  }
  &:active {
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.11);
  }
  &:focus {
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.11);
  }
}

.buttonWrapper {
  margin: 0 24px;
  background-color: #fff;
}
