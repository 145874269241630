@import '../../../theme/constants.scss';

.wrapper {
  margin: 0;
  padding-top: 80px;
  min-height: 100vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.fixedWrapper {
  z-index: 99999;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: #fff;
  margin: 0;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.fixedFormWrapper {
  margin: 36px auto;
  width: 426px;
  > div {
    margin-bottom: 38px;
    > div:first-child {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: #3b3f43;
      margin-bottom: 13px;
    }
    > div:last-child {
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
      color: #3b3f43;
    }
  }
}

.formWrapper {
  margin: 0 auto 36px auto;
  width: 52%;
  form {
    margin-top: 30px;
  }
}

.headerWrapper {
  width: 426px;
  > h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #3b3f43;
  }
  > div {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: #82898f;
  }
}

.options {
  display: flex;
  justify-content: space-between;
  margin: 16px auto 8px auto;
  p {
    color: #3f63e2;
    &:hover {
      cursor: pointer;
    }
  }
}

.backButtonWrapper {
  position: absolute;
  background-color: #fff;
  z-index: 999;
  top: 79px;
  width: 52%;
}


@media (max-width: $smallMobile) {
  .formWrapper {
    width: 85%
  }

  .backButtonWrapper {
    width: 85%
  }
}

@media (max-width: $mobile) {
  .formWrapper {
    width: 80%
  }

  .backButtonWrapper {
    width: 80%
  }
}
