.modal {
  > div > div {
    overflow-y: auto;
    width: 100%;
  }
}
.modalBody {
  margin: 0;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  justify-content: space-between;
  padding: 24px;
  width: 100%;
}

.label {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  > div {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: #9ba3ab;
  }
}

.transactionCard {
  background-color: #f3f3ff;
  border-radius: 8px;
  padding: 16px;
  width: 249px;
  margin: 20px 0;
  background-color: aqua;
}

.modalLeft {
  padding: 0px 24px 24px 24px;
  overflow-x: hidden;
  overflow-y: auto;
  width: 50%;
}
.topRow {
  margin: 20px;
}

.modalRight {
  padding: 0px 24px 24px 24px;
  // overflow: hidden;
  overflow-x: hidden;
  overflow-y: auto;
  width: 50%;
}
.paymentMethod {
  display: inline-flex;
  justify-content: space-between;
  width: 250px;
}
.checkbox {
  display: flex;
  flex-direction: column;
  margin: 0;
}

.confirmContainer {
  margin: auto;
  width: auto;
  padding: 10px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;

  > span {
    color: #82898f;
    text-align: center;
    font-size: 12px;
  }
}
.inputAmount {
  > span {
    margin-left: 65px;
    color: black;
    border-right: none;
  }

  > input {
    background-color: #f6f7fa;
    font-size: 24px;
    font-weight: 700;
    border: 1px solid #ffffff;
  }
}

.mobileInputPrefix {
  // margin-top: 8px;
  > div {
    display: flex !important;
    align-items: center !important;
    height: 40px !important;
    background: none !important;
    border: none !important;
  }
}

.inputRemoveIcon {
  color: #9ba3ab;
  cursor: pointer;
}
.addressLabel {
  display: inline-flex;
  align-content: space-between;
  width: 400px;
  margin: auto;
}
.uploaderComponent {
  > div {
    > div:last-child {
      background-color: #fff;
      border: none;
      width: 10px;
      height: 20px;
      margin: 40px 0 0 20px;
    }
  }

  > div > div {
    > div {
      border: none !important;
      border-radius: 100%;
    }
    > span {
      border-radius: 100% !important;
      border: none !important;
      width: 30%;
    }
  }
  > div > div > div > div {
    border: 1px solid #f0f0f0;
    border-radius: 100%;
  }
}
.beneficialOwnerCard {
  background-color: #f6f7fa;
  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .beneficialActions {
    margin-left: 105px;
    > span:first-child {
      margin-left: 20px;
      color: #7372ff;
      cursor: pointer;
    }
    > span:last-child {
      margin-left: 20px;
      color: #ee6969;
      cursor: pointer;
    }
  }
}

.disabledField {
  border: none;
  box-shadow: none;
  background-color: #e9edf4;
  border-radius: 6px;
}

.ant-tabs-tab {
  justify-content: center !important;
}

.tableRow {
  display: flex;
  flex: 10%;
}

.table {
  margin: 1.5rem 0 0 3rem;
  width: fit-content;
  border-collapse: collapse;
}

.table thead {
  font-weight: 500;
}

.table th, .table td {
  padding: 8px;
  text-align: left;
}
