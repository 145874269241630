@import '../../../theme/constants.scss';

.wrapper {
  margin: 0;
  padding-top: 80px;
  min-height: 100vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.accountFormWrapper {
  margin: 0;
  padding-top: 120px;
  // height: 100vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.stepsWrapper {
  // background: #fff0;
  background: rgb(246 247 250);
  padding-top: 26px;
  padding-bottom: 20px;
  margin: 0;
  position: fixed;
  top: 0;
  right: 0;
  // left: 42%;
  z-index: 99;
  width: 100%;
}

.ant-upload span {
  padding: 12px 0 !important;
}

.dragger-docs span {
  padding: 12px 0 !important;
}

.stepper {
  width: 25%;
  margin: 0 auto;
  // position: relative;
}

.step {
  // width: calc(100% / 8);
  margin-left: -42px;
}

.stepperBackButton {
  margin-top: 20px;
  margin-left: 24%;
}

.headerWrapper {
  text-align: left;
  margin: 24px 0;
  width: 416px;
  h1 {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    color: #3b3f43;
    margin: 8px 0px;
  }
  > div {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    color: #9ba3ab;
  }
}

.formWrapper {
  margin: 0 auto 36px auto;
  width: 100%;
  padding: 0 5rem 0;
}

.formWrapperSignup {
  margin: 0 auto 36px auto;
  width: 52%;
}

.formBOInstructionsWrapper {
  margin: 0 auto 36px auto;
  width: 52%;
  padding: 0 5rem 0;
}

.description {
  width: 368px
}

.accountTypeWrapper {
  margin: auto;
  height: 100vh;
  width: 50%;
  display: flex;
  flex-direction: column;
}

.links {
  margin: 28px 0;
  text-align: center;
  font-size: 0.8rem;
  color: grey;
  > span {
    color: #9ba3ab;
  }
}

.otpWrapper {
  margin: 10px auto 40px auto;
  width: 52%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.otp {
  margin: 10px 10px 0 0 !important;
  input {
    padding-left: 21px;
    width: 54px;
  }
}

.label {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  // margin-top: 30px;
  > div {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: #9ba3ab;
  }
}

.formLabel {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  margin: 24px 0;
}

.success {
  margin: auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  > div:last-child {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: #9ba3ab;
    width: 60%;
  }
}

.radioBtn {
  height: 100px;
  width: 30.2vw;
  color: #3b3f43;
  border-width: 1px !important;
  border-style: solid !important;
  border-color: #d1d5dd;
  &:first-child {
    border-left-width: 1px !important;
    border-left-style: solid !important;
    border-left-color: #d1d5dd;
  }
  // border: 1px solid #d1d5dd;
  // border-left: 1px solid #d1d5dd !important;
  &:hover {
    box-shadow: 0px 4px 10px 3px rgba(0, 0, 0, 0.11);
  }
  &:focus {
    border-color: #3f63e2 !important;
    border-left-color: #3f63e2 !important;
  }
  &:active {
    border-color: #3f63e2 !important;
    border-left-color: #3f63e2 !important;
  }
  > span {
    // position: relative;
    // top: 20px;
    > span {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      > span:last-child {
        margin-left: 43px;
      }
    }
  }
  .radioBtnText {
    display: flex;
    flex-direction: column;
    text-align: left;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    > div {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 18px;
      color: #9ba3ab;
      width: 276px;
      margin-top: 6px;
    }
  }
}

.mobileInputPrefix {
  // margin-top: 16px;
  > div {
    display: flex !important;
    align-items: center !important;
    height: 40px !important;
    background: none !important;
    border: none !important;
  }
}
.radioGroup {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.uploadButton {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100px;
  background-color: #ffffff;
  border: 1px solid #d1d5dd;
  border-style: dashed;
  border-radius: 6px;
}
.removeButton {
  height: 56px;
  width: 100%;
  background-color: #fff1f1;
  color: #ee6969;
  border-color: #fff1f1;
}
.removeButton:hover {
  height: 56px;
  width: 100%;
  background-color: #fde3e3;
  color: #ee6969;
  border-color: #fde3e3;
}
.actionButtons {
  margin-top: 20px;
}
.alretBox {
  margin-bottom: 10px;
}

.passHint {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 5px;

  span {
    display: inline-block;
    background-color: #e8e8e8;
    width: 75px;
    height: 10px;
    border-radius: 2px;
  }

  div {
    font-weight: 500;
  }
}

.formLabelOwner {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  margin: 24px 0 5px;
}

.infoLabel {
  margin-bottom: 24px;

  button {
    padding: 0;

    span {
      text-decoration: underline;
    }
  }
}

.tandc {
  margin: 10px 0;
}

.warningSentence {
  font-style: normal;
  color: #ee6969;
  font-weight: 500;
  margin-top: 10px;
  font-size: 14px;
  line-height: 19px;
}

.infoIcon {
  color: rgba(0, 0, 0, 0.45);

  &:hover {
    color: rgba(0, 0, 0, 0.85);
  }
}

.customDisabledButton {
  width: 100% !important;
}
.accountTypeButton {
  display: inline-block;
  width: calc(50% - 4px);
  margin: 0 0 0 4px;
  float: right;
}

.form {
  width: 100%;
  div {
    width: 100%
  }
}

@media (max-width: $smallMobile) {
  .formWrapper {
    width: 95%
  }
}

@media (max-width: $mobile) {
  .formWrapper {
    width: 80%
  }

  .stepper {
    width: 80%
  }

  .stepperBackButton {
    width: 80%;
    margin: 0 auto 0 auto;
    padding-top: 8px;
    padding-bottom: 0
  }

  .headerWrapper {
    width: 90%;
    .description{
      width: 100%;
    }
  }

  .accountFormWrapper {
    padding-top: 80px
  }

  .radioBtn{
    width: 100%;
    padding: 0;
    .radioBtnText{
      width: 70%;
      > div {
        width: 100%
      }
    }
    > span {
      > span {
        > span:last-child {
          margin-left: 0;
        }
      }
    }
  }

  .stepsWrapper {
    width: 100%;
    left: 0;
  }

  .accountTypeButton {
    display: block;
    width: 100%;
    float: none;  
  }
}


.text{
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  color: #82898F
}

.ant-select-selector {
  max-width: 50vw !important;
  height: fit-content !important;
}

.businessInfoSelect {
  max-width: 50vw !important;
  height: fit-content !important;
}
