@import '../../../theme/constants.scss';

.wrapper {
  margin: 0;
  display: flex;
  justify-content: space-between;
}

.wrapperContent {
  margin: 0;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 42%;
  background: linear-gradient(
    180deg,
    #334db0 0%,
    rgba(104, 58, 250, 0.85) 100%
  );
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 999;
}

.logo {
  width: 200px;
  margin-top: 64px;
  margin-left: 69px;
  margin-bottom: 50px;
}

.carousel {
  margin-left: 69px;
}

.carouselContent {
  height: 80vh;
  h3 {
    color: #fff;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    display: flex;
    align-items: center;
    width: 251px;
    margin-top: 50px;
  }
  p {
    color: #cfc9ff;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
  }
}

.carouselImg {
  margin: auto 0;
  height: 60%;
}

.sectionWrapper {
  position: absolute;
  left: 42%;
  top: 0;
  right: 0;
  // bottom: 0;
  background: #fff;
}


@media (max-width: $mobile) {
  .sectionWrapper {
    left: 0
  }
  
  .carousel {
    display: none;
  }

  .sidebar {
    width: 100%;
    height: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
  }

  .logo {
    margin: 0;
    width: 40%;
  }
}
