@import '../../../theme/constants.scss';

.wrapper {
  margin: 0;
  padding-top: 100px;
  height: 100vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.headerWrapper {
  text-align: left;
  margin: 30px 0 20px 0;
  width: 416px;
  h1 {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    color: #3b3f43;
    margin: 8px 0px;
  }
  > div {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    color: #9ba3ab;
  }
}

.steps {
  margin: 46px auto;
}

.formWrapper {
  margin: 10px auto 40px auto;
  width: 52%;
}

.links {
  margin: 28px auto;
  text-align: center;
  > span {
    color: #9ba3ab;
  }
}

.otpWrapper {
  width: 52%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.otp {
  input {
    padding-left: 21px;
    margin-right: 8px;
    width: 54px;
  }
}

.options {
  display: flex;
  justify-content: space-between;
  p {
    color: #3f63e2;
  }
}

.otpSignIn {
  height: 120px;
  padding: 16px 20px 16px 16px;
  background-color: #fcfdff;
  div {
    font-size: 14px;
    font-weight: 600;
    color: #3b3f43;
  }
  p {
    height: 66px;
    color: #9ba3ab;
    font-weight: 400;
    font-size: 14px;
    padding: 0 24px 16px 28px;
  }
}

.inputsWrapper {
  display: flex;
  flex-direction: row;
}

.backButtonWrapper {
  position: absolute;
  background-color: #fff;
  z-index: 996;
  top: 0;
  width: 52%;
  padding-top: 12vh;
  margin-right: 6px;
}

.passHint {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 5px;

  span {
    display: inline-block;
    background-color: #e8e8e8;
    width: 75px;
    height: 10px;
    border-radius: 2px;
  }

  div {
    font-weight: 500;
  }
}


@media (max-width: $smallMobile) {
  .formWrapper {
    width: 85%
  }

  .backButtonWrapper {
    width: 85%
  }
}

@media (max-width: $mobile) {
  .formWrapper {
    width: 80%
  }

  .backButtonWrapper {
    width: 80%
  }
}
