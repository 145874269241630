.wrapper {
  margin: 40px 0;
  display: flex;
  flex-direction: column;
}

.paymentDetails {
  > h3 {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    color: #3b3f43;
    margin-bottom: 24px;
  }
}

.buttonWrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  > button {
    width: 49%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 12px 0;
    border: 1px solid #000;
    color: #000;
  }
  > button:first-child {
    height: 50px;
    width: 49%;
  }
  > button:last-child {
    background-color: #000;
    color: #fff;
    height: 50px;
  }
}

.divider {
  margin: 40px 0;
  border-top: 1px solid #d1d5dd;
  display: flex;
  justify-content: center;
  > div {
    text-align: center;
    width: 100px;
    background: #fff;
    margin-top: -9px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #3b3f43;
  }
}

.paymentForm {
  > div > label > span {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 30px;
    color: #3b3f43;
  }
}

.label {
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  color: #3b3f43;
  margin-bottom: 0;
}

.submitButton {
  width: 100%;
  height: 50px;
  margin: 0px 10px 15px 0px;
}

.ccWrapper {
  width: 100%;
}

.cardFieldsRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-self: stretch;
}

/* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-width: 320px) and (max-width: 480px) {
  /* Styles */
  .wrapper {
    padding: 0 32px;
  }
  .submitButton {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: auto !important;
    margin: 5px 10px 15px 10px;
  }
  .cardFieldsRow {
    div:nth-child(1) > div > label > span {
      margin-top: 8px;
    }
  }
}

/* Smartphones (landscape) ----------- */
@media only screen and (min-width: 321px) {
  /* Styles */
  .wrapper {
    padding: 0 32px;
  }
}

/* Smartphones (portrait) ----------- */
@media only screen and (max-width: 320px) {
  /* Styles */
  .wrapper {
    padding: 0 32px;
  }
  .submitButton {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: auto !important;
    margin: 5px 10px 15px 10px;
  }
}

/* iPads (portrait and landscape) ----------- */
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  /* Styles */
  .wrapper {
    padding: 0 32px;
  }
}

/* iPads (portrait) ----------- */
@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  /* Styles */
  .wrapper {
    padding: 0 32px;
  }
  .submitButton {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: auto !important;
    margin: 5px 10px 15px 10px;
  }
}

/* Desktops and laptops ----------- */
@media only screen and (min-width: 1224px) {
  /* Styles */
  .wrapper {
    padding: 0;
    width: 40vw;
  }
}

/* Large screens ----------- */
@media only screen and (min-width: 1824px) {
  /* Styles */
  .wrapper {
    padding: 0;
    width: 40vw;
  }
}

.hasFile {
  margin-bottom: 0 !important;

  & > :global(.ant-form-item-control) {
    display: none;
  }
}

.hideControl {
  display: none;
}

.uploadCard {
  position: relative;
  border: 1px solid #9f9cff;
  height: 120px;
  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: row;
  transition: 0.5s;

  > div :last-child {
    width: 150px;
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #9f9cff;
  }
}

.uploadCard:hover {
  background-color: #edeaec;
  transition: 0.5s;
}
.closeIcon {
  cursor: pointer;
  position: absolute;
  top: 8px;
  right: 16px;
  color: #9ba3ab;
}

.accountNumberField iframe {
  width: 100% !important;
  margin-top: 0px !important;
}
