.wrapper {
  margin: 0;
  padding: 0;
  flex-direction: column;
  align-items: center;
}

.bodyWrapper {
  display: flex;
  justify-content: space-between;
}

.body {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.layoutLeft {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 36px 28px 20px 36px;
}

.layoutRight {
  width: 35%;
  display: flex;
  flex-direction: column;
  background-color: #eef0f5;
  overflow: auto;
  padding: 20px 28px 20px 36px;
}

.headerTopLeft {
  h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 34px;
    color: #3b3f43;
  }
  > div {
    > span {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 150%;
      color: #82898f;
    }
    > span:last-child {
      font-weight: bold;
      margin-left: 5px;
    }
  }
}

.detailsCardWrapper {
  > div {
    padding: 0;
    margin: 0;
  }
  margin: 24px 0;
  width: 100%;

  .detailsCardTop {
    background-color: #7372ff;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    height: 132px;
    text-align: center;
    display: flex;
    flex-direction: row;
    // color: #E5E7EB;
    > div {
      margin: auto;
      color: #fff;
      font-size: 12px;
      > div {
        > span:first-child {
          font-size: 15px;
        }
        > span:last-child {
          font-size: 38px;
          font-weight: 700;
        }
      }
    }
  }
}

.tableCardBody {
  margin: 5px 24px 12px 24px;
}

.integrationsHeader {
  margin-bottom: 32px;
  > div:last-child {
    display: inline-flex;
    > span {
      font-size: 30px;
      font-weight: 700;
    }
    > div {
      display: flex;
      flex-direction: column;
      margin-left: 8px;
      > span:last-child {
        font-weight: 700;
      }
    }
  }
}
.headerTopRight {
  align-self: flex-end;
  padding-top: 20px;
  margin: 0 0 60px 0;
  background-color: #eef0f5;
}
.subscriptionPlanCard {
  .subscriptionHeading {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #ffffff80;
    text-align: center;
    margin-bottom: 18px;
  }
  > div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    &:before {
      content: none !important;
    }
    &:after {
      content: none !important;
    }
  }
  width: 100%;
  margin: 5px 0;
  background: linear-gradient(95.02deg, #7372ff 5.16%, #9772ff 85.19%);
  border: none;
  box-sizing: border-box;
  box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.03);
  .arrowBtnWithBg {
    color: #7372ff;
    background-color: #ffffff;
    border-radius: 50%;
    cursor: pointer;
  }
  .cardAction {
    color: #fff;
    font-weight: 700;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    > div > div {
      font-weight: normal;
      width: 100%;
    }
  }
  .CATCardTop {
    padding: 0 0 5px 0;
    margin: auto;
    color: #fff;
  }
}

.statusCard {
  cursor: pointer;
  &.disabled {
    opacity: 0.5;
  }
  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    &:before {
      content: none !important;
    }
    &:after {
      content: none !important;
    }
  }
  width: 100%;
  height: 57px;
  margin: 5px 0;
  background: #fff;
  border: none;
  box-sizing: border-box;
  box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.03);
  .cardAction {
    color: #3b3f43;
    font-size: 12px;

    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    > div:first-child {
      display: flex;
      align-items: center;
      > span:last-child {
        margin-left: 12px;
      }
    }
    > span {
      font-size: 12px;
      display: inline-flex;
      margin: 0;
    }
    .statusIcon {
      z-index: 1;
    }
    // .optionIconBackdropLight {
    //   position: absolute;
    //   border-radius: 50%;
    //   width: 30px;
    //   height: 30px;
    //   left: 35px;
    //   top: 45px;
    //   background: #f3f3ff;
    // }
    .arrowBtnWithBg {
      color: #fff;
      background-color: #7372ff;
      border-radius: 50%;
      cursor: pointer;
    }
  }
}

.valueCard {
  height: var(--topRow-height);
  width: 100%;
  padding: 20px 0 10px 0;

  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    margin: 0;
    height: 100%;
  }
  color: #3b3f43;
}

.incomingFunds {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 30px 10px 10px;
  margin: 0;
  height: 100%;
  width: 33.3%;
  text-align: left;
  h3 {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
  }
  .icon {
    background-color: #e1fff4;
    color: #1dbc83;
    border-radius: 50%;
    width: 27px;
    height: 27px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 25px;
  }
  .amount {
    > div {
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      display: flex;
      align-items: flex-start;
      justify-content: flex-end;
    }
    span {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 19px;
      margin-right: 5px;
    }
    > span:last-child {
      background-color: #f6f7fa;
      color: #82898f;
      border-radius: 41px;
      padding: 2px 6px 4px 6px;
      margin: 5px 0 0 0;
      display: inline-flex;
    }
  }
}

.outgoingFunds {
  border-left: 1px solid #f0f0f0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 33.3%;
  padding: 10px 30px 10px 10px;
  margin: 0;
  text-align: left;

  h3 {
    color: #ee6969;

    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
  }
  .icon {
    background-color: #faeaea;
    color: #ee6969;
    border-radius: 50%;
    width: 27px;
    height: 27px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 25px;
  }
  .amount {
    > div {
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      display: flex;
      align-items: flex-start;
      justify-content: flex-end;
    }
    span {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 19px;
      margin-right: 5px;
    }
    > span:last-child {
      background-color: #f6f7fa;
      color: #82898f;
      border-radius: 41px;
      padding: 2px 6px 4px 6px;
      margin: 5px 0 0 0;
      display: inline-flex;
    }
  }
}

.cashflow {
  border-left: 1px solid #f0f0f0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 33.3%;
  padding: 10px 0 10px 10px;
  margin: 0;
  text-align: left;
  h3 {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
  }
  .iconOutgoing {
    background-color: #faeaea;
    color: #ee6969;
    border-radius: 50%;
    width: 27px;
    height: 27px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 25px;
  }
  .iconIncoming {
    background-color: #e1fff4;
    color: #1dbc83;
    border-radius: 50%;
    width: 27px;
    height: 27px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 25px;
  }
  .amount {
    > div {
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      display: flex;
      align-items: flex-start;
      justify-content: flex-end;
    }
    span {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 19px;
      margin-right: 5px;
    }
    > span:last-child {
      background-color: #f6f7fa;
      color: #82898f;
      border-radius: 41px;
      padding: 2px 6px 4px 6px;
      margin: 5px 0 0 0;
      display: inline-flex;
    }
  }
}

.optionCardLight {
  cursor: pointer;
  > div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    &:before {
      content: none !important;
    }
    &:after {
      content: none !important;
    }
    > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  width: 49%;
  // max-height: var(--topRow-max-height);
  height: var(--topRow-height);
  // height: 137px;
  // margin: 0 0 0 16px;
  background: #ffffff;
  border: none;
  box-sizing: border-box;
  box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.03);
  .optionIcon {
    z-index: 99;
  }
  .optionCardComingSoonLabel {
    color: #d3b25a;
    text-transform: uppercase;
    font-style: normal;
    font-weight: normal;
    font-size: 9px;
    line-height: 150%;
  }
  .optionIconBackdropLight {
    position: absolute;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    left: 35px;
    top: 35px;
    background: #f6f7fa;
  }
  .arrowBtnWithBg {
    color: #fff;
    background-color: #7372ff;
    border-radius: 50%;
    cursor: pointer;
  }
  .cardAction {
    z-index: 999;
    color: #3b3f43;
    font-weight: 700;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    span {
      width: 100px;
    }
  }
}

.card, .gradientCard {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  margin-top: 5px;
  margin-bottom: 10px;
  border-radius: 8px;
  background: #7372FF;
  cursor: pointer;
}

.cardText {
  color: white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.bottomRow {
  width: 100%;
  display: flex;
  justify-content: space-between;
  > div {
    width: 50%;
  }
  > div:first-child {
    margin-right: 16px;
  }
}
.middleRow {
  width: 100%;
  display: inline-flex;
  justify-content: space-between;

  > div:last-child {
    margin: 0 0 0 0 !important;
  }
}
.notificationWrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 16px 0;
}
